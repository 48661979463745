import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://tamas-rabel.github.io/cortex/sheet.html"
      }}>{`https://tamas-rabel.github.io/cortex/sheet.html`}</a>{`
Trait Sets:`}</p>
    <ul>
      <li parentName="ul">{`Distinctions`}</li>
      <li parentName="ul">{`Attributes`}</li>
      <li parentName="ul">{`Skills + Specialties`}</li>
      <li parentName="ul">{`Abilities`}</li>
      <li parentName="ul">{`Signature Assets`}</li>
    </ul>
    <h2>{`Concept`}</h2>
    <p>{`Before creating any traits or assigning dice, the most important step in character creation is your `}<strong parentName="p">{`Concept`}</strong>{`. Consider the type of character you want to play, what sort of role your character would like to have in the story. Think about what their upringing was like, their personality, and the sorts of abilities you want your character to have. `}</p>
    <p>{`Do you want to play a powerful `}<em parentName="p">{`Farseeker`}</em>{` who manipulates the threads of space and gravity? Or perhaps a `}<em parentName="p">{`Deep Dweller`}</em>{` working for the Holden Corporation maintaining underground pipelines. Use `}<em parentName="p">{`The Dark Between`}</em>{` campaign setting to help generate ideas. Once you have a solid concept in mind, you can move on to creating your character’s `}<strong parentName="p">{`Trait Sets`}</strong>{`.`}</p>
    <h2>{`Distinctions`}</h2>
    <p><strong parentName="p">{`Distinctions`}</strong>{` are a Character’s most important trait set. Characters have `}<strong parentName="p">{`three`}</strong>{` distinction traits that give establish their background, personality, role in the story, ancestory, vocation, or heritage. It is up to you to decide what these distinctions are—for some characters, their ancestory might be important to them. For others, their profession. Work with the DM to come up with the best distinctions that fit with your character’s `}<strong parentName="p">{`Concept`}</strong>{`.`}</p>
    <h3>{`SFX`}</h3>
    <p>{`Traits, including Disctinctions, sometimes have `}<em parentName="p">{`SFX`}</em>{` attached to them, which are kinds of “bonuses” or “benefits” that occur when certain stipulations are met.`}</p>
    <p>{`All Distinctions get the `}<strong parentName="p">{`Hinder`}</strong>{` distinction: “Gain a PP when you switch out this distinction’s 8 for a 4”`}</p>
    <p>{`In addition, `}<strong parentName="p">{`The Dark Between`}</strong>{` characters will get 3 more `}<strong parentName="p">{`SFX`}</strong>{` to distribute across their distinctions as they see fit. Work with the DM to determine exactly what these SFX are—certain ancestories, backgrounds, or vocations may come with specific `}<strong parentName="p">{`SFX`}</strong>{`. More `}<em parentName="p">{`SFX`}</em>{` can be added to distinctions through `}<em parentName="p">{`Character Growth`}</em>{`.`}</p>
    <h2>{`Attributes`}</h2>
    <p>{`Attributes start at 8. An attribute may be stepped down to step another attribute up. No attribute may start higher than 10.`}</p>
    <ul>
      <li parentName="ul">{`Strength`}</li>
      <li parentName="ul">{`Dexterity`}</li>
      <li parentName="ul">{`Intellect`}</li>
      <li parentName="ul">{`Awareness`}</li>
      <li parentName="ul">{`Conviction`}</li>
    </ul>
    <h3>{`Skills`}</h3>
    <p>{`Skills start at 4. At creation, Players have 9 step-up points.`}</p>
    <ul>
      <li parentName="ul">{`Athletics`}</li>
      <li parentName="ul">{`Acrobatics`}</li>
      <li parentName="ul">{`Combat`}</li>
      <li parentName="ul">{`Deception`}</li>
      <li parentName="ul">{`Engineering`}</li>
      <li parentName="ul">{`Intimidation`}</li>
      <li parentName="ul">{`Knowledge`}</li>
      <li parentName="ul">{`Medicine`}</li>
      <li parentName="ul">{`Perception`}</li>
      <li parentName="ul">{`Performance`}</li>
      <li parentName="ul">{`Persuasion`}</li>
      <li parentName="ul">{`Sleight of Hand`}</li>
      <li parentName="ul">{`Stealth`}</li>
    </ul>
    <h4>{`Skill Specialties`}</h4>
    <p>{`Start at a rating of 6 and can be upgraded, no specific list. Characters start out with 0 specialties, but can be adding through `}<em parentName="p">{`Character Growth`}</em>{`.`}</p>
    <h3>{`Abilities`}</h3>
    <p>{`Characters start with 3 `}<strong parentName="p">{`Abilities`}</strong>{`, traits that represent powers or aptitudes that go above and beyond the average person in the world. For `}<em parentName="p">{`Acronists`}</em>{`, these abilities represent the 3 aspects of their chosen Primal Spirit. For other characters, their abilities can represent a number of different things depending on their concept—special equipment, weapons, gear, or specific powers/traits inherited from their ancestory or background. Each character starts with two 6 rated abilities, one 8 rated ability, and a single SFX attached to each ability. Higher ratings and more SFX can be added through `}<em parentName="p">{`Character Growth`}</em>{`.`}</p>
    <h3>{`Signature Assets`}</h3>
    <p>{`Characters start with a single `}<strong parentName="p">{`Signature Asset`}</strong>{`, rated 6, which represents a particularly iconic weapon, item, pet, or something else entirely that is tied to the Character. Signature assets can also represent more nebulous things, such as relationships with important organizations or characters within the world. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      